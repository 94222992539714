<template>
  <div>
    <SpinnerComponent v-if="isLoading" />
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt-6"
      :class="{ 'blur-filter': isLoading }"
    >
      <div class="alert" :class="[`alert-${apiStatus}`]" v-if="apiStatus" >
        {{ apiMessage }}
      </div>
      <div class="row">
        <div class="col-md-6">
          <Card>
            <div class="row justify-content-between">
              <div class="col-md-3">
                <base-input label="Czy aktywny?">
                  <base-switch v-model="active" />
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="Czy fakturowany?">
                  <base-switch v-model="invoiced" />
                </base-input>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input label="Nazwa prezentu" v-model="name" />
              </div>
              <div class="col-md-6">
                <base-input label="Który produkt jest giftem?">
                  <el-select v-model="giftProduct"
                             filterable
                             placeholder="Wybierz produkt"
                  >
                    <el-option v-for="option in productList"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input
                  label="Minimalna wartość koszyka"
                  v-model="minGrossPriceLocal"
                  @change="e => handleChange({
                    field: 'minGrossPrice',
                    value: e.target.value
                  })"
                />
              </div>
              <div class="col-md-6">
                <base-input
                  label="Cena prezentu"
                  v-model="giftGrossPriceLocal"
                  @change="e => handleChange({
                    field: 'giftGrossPrice',
                    value: e.target.value
                  })"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <base-input label="Data od">
                  <datepicker v-model="dateFrom" />
                </base-input>
              </div>
              <div class="col-md-6">
                <base-input label="Data do">
                  <datepicker v-model="dateTo" />
                </base-input>
              </div>
            </div>
          </Card>
        </div>
        <div class="col-md-6">
          <Card>
            <div>
              <base-input label="Gift dla wybranych linii" placeholder="">
                <el-select v-model="lines"
                           multiple
                           filterable
                           placeholder="Wybierz linie, których dotyczy gift"
                >
                  <el-option v-for="option in linesList"
                             :key="option.value"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div>
              <base-input label="Gift dla wybranych kategorii" placeholder="">
                <el-select
                  v-model="categories"
                  multiple
                  filterable
                  reserve-keyword
                  placeholder="Wybierz kategorie, których dotyczy gift"
                >
                  <el-option v-for="option in categoryList"
                             :key="option.value"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div>
              <base-input label="Gift dla wybranych produktów" placeholder="">
                <el-select v-model="products"
                           multiple
                           filterable
                           placeholder="Wybierz produkty, których dotyczy gift"
                >
                  <el-option v-for="option in productList"
                             :key="option.value"
                             :label="option.label"
                             :value="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </Card>
        </div>
      </div>
      <div class="text-right">
        <div class="btn btn-primary" @click="handleSubmit">Zapisz gift</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import Card from "@/components/Cards/Card";
import Datepicker from "@/components/Datepicker";
import { Select, Option } from 'element-ui'
import SpinnerComponent from "@/components/Atoms/Spinner";

export default {
  name: 'GiftAdd',
  components: {
    SpinnerComponent,
    BreadcrumbHeader,
    Card,
    Datepicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      isLoading: false,
      minGrossPriceLocal: '',
      giftGrossPriceLocal: '',
    }
  },
  computed: {
    productList() {
      return this.$store.state.GiftManageModule.productList
    },
    categoryList() {
      return this.$store.state.GiftManageModule.categoryList
    },
    linesList() {
      return this.$store.state.GiftManageModule.linesList
    },
    name: {
      get() {
        return this.$store.state.GiftManageModule.form.name
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'name',
          values: newVal
        })
      }
    },
    giftProduct: {
      get() {
        return this.$store.state.GiftManageModule.form.giftProduct
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'giftProduct',
          values: newVal
        })
      }
    },
    active: {
      get() {
        return this.$store.state.GiftManageModule.form.active
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'active',
          values: newVal
        })
      }
    },
    invoiced: {
      get() {
        return this.$store.state.GiftManageModule.form.invoiced
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'invoiced',
          values: newVal
        })
      }
    },
    dateFrom: {
      get() {
        const date = this.$store.state.GiftManageModule.form.dateFrom
        if ( date ) {
          return new Date(date)
        }
        return new Date()
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'dateFrom',
          values: newVal
        })
      }
    },
    dateTo: {
      get() {
        const date = this.$store.state.GiftManageModule.form.dateTo
        if ( date ) {
          return new Date(date)
        }
        return new Date()
      },
      set(newVal) {
        window.date = newVal
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'dateTo',
          values: newVal
        })
      }
    },
    apiStatus() {
      return this.$store.state.GiftManageModule.apiStatus
    },
    apiMessage() {
      return this.$store.state.GiftManageModule.apiMessage
    },
    categories: {
      get() {
        return this.$store.state.GiftManageModule.form.categories
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'categories',
          values: newVal
        })
      }
    },
    lines: {
      get() {
        return this.$store.state.GiftManageModule.form.lines
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'lines',
          values: newVal
        })
      }
    },
    products: {
      get() {
        return this.$store.state.GiftManageModule.form.products
      },
      set(newVal) {
        this.$store.commit('GiftManageModule/updateValues', {
          field: 'products',
          values: newVal
        })
      }
    },
    breadcrumb() {
      return [
        {title: 'Promocje'},
        {title: 'Prezenty', link: {name: 'gift-list'}},
        {title: 'Nowy prezent'}
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('GiftManageModule/fetchLines');
    await this.$store.dispatch('GiftManageModule/fetchCategories');
    await this.$store.dispatch('GiftManageModule/fetchProducts');

    if ( this.$route.query.id || this.$route.params.id ) {
      const id = this.$route.query.id || this.$route.params.id
      await this.$store.dispatch('GiftManageModule/fetchGiftInfo', id )
    }

    ['minGrossPrice', 'giftGrossPrice'].forEach( field => {
      this[`${field}Local`] = this.parsePrice(this.$store.state.GiftManageModule.form[field])
    })
    this.isLoading = false;
  },
  methods: {
    ...mapActions('GiftManageModule', ['saveGiftInfo', 'deleteGift', 'fetchProducts']),
    parsePrice(price) {
      return ( price / 100 ).toFixed(2).replace('.', ',') + ' zł'
    },
    async handleSubmit() {
      await this.saveGiftInfo(this.$route.query.id || this.$route.params.id)
    },
    async handleChange({ field, value }) {

      this.$store.commit('GiftManageModule/updateValues', {
        field,
        values: (value.split(' zł').join('').replace(',', '.') * 100).toString()
      })

      this[`${field}Local`] = this.parsePrice(this.$store.state.GiftManageModule.form[field])
    },
    async handleDelete() {
      await this.deleteGift('bfa5d7f8-aaf1-402c-a4ae-50259a00a608');
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('GiftManageModule/resetForm')
    next();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style lang="scss">
  .blur-filter {
    filter: blur(2px);
  }
</style>
